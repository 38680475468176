'use strict';

/**
 * Select the subjects based on a parent.
 */
angular.module('uasApp').component('subjectCompare', {
  templateUrl: 'es6/subjects/subject.compare.html',
  bindings: {
    categoryId: '<',
    entity: '<',
    parent: '<',
    operations: '<',
    operationsToEdit: '<',
    onSaved: '&',
    onCancel: '&'
  },
  controllerAs: 'subjectCompareController',
  controller: function ($q, activeFilter, EntityService, Subject) {
    const subjectCompareController = this;

    subjectCompareController.$onInit = function () {
      subjectCompareController.reference = EntityService.toReference(subjectCompareController.entity);
      loadData();
    };

    function loadData() {
      $q.all([
        Subject.query({
          categoryId: subjectCompareController.categoryId,
          entityType: EntityService.getType(subjectCompareController.parent),
          entityId: subjectCompareController.parent.id
        }).$promise,
        Subject.query({
          categoryId: subjectCompareController.categoryId,
          entityType: EntityService.getType(subjectCompareController.entity),
          entityId: subjectCompareController.entity.id
        }).$promise
      ]).then(([parentSubjects, subjects]) => {
        subjectCompareController.subjects = _.map(parentSubjects, (parentSubject) => {
          const { type, value } = parentSubject;
          let subject = _.find(subjects, (it) => it.type.id === type.id);
          if (angular.isDefined(subject)) {
            subject.enabled = activeFilter(subject);
            if (!subject.enabled) {
              subject.value = parentSubject.value;
            }
          } else {
            subject = {
              enabled: false,
              entity: subjectCompareController.reference,
              type,
              value
            };
          }
          return _.extend(subject, { parentValue: value });
        });
      });
    }

    subjectCompareController.onChange = function (subject) {
      toggle(subject).then(() => {
        subjectCompareController.onSaved();
      });
    };

    function toggle(subject) {
      const comment = {
        message: ''
      };

      if (subject.enabled) {
        const content = Subject.toModel(subject);
        return Subject.store({
          comment,
          content
        }).$promise;
      }

      return Subject.remove({
        comment,
        id: subject.id
      }).$promise;
    }

    subjectCompareController.cancel = function () {
      subjectCompareController.onCancel();
    };
  }
});
