angular.module('uasApp').component('linkBuilder', {
  bindings: {
    entity: '<',
    operations: '<',
    categoryId: '<?',
    workflowMode: '<',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/link/link.builder.html',
  controllerAs: 'linkBuilderController',
  controller: function ($q, AwardQualification, EntityService, Language, Link, LinkType, SecurityService) {
    const linkBuilderController = this;

    linkBuilderController.$onInit = function () {
      linkBuilderController.operationsToEdit = linkBuilderController.workflowMode === true ? 'EDIT_LINKS_WORKFLOW' : 'EDIT_LINKS';
      linkBuilderController.editable = linkBuilderController.isReadOnly !== true && SecurityService.isAllowed(linkBuilderController.operationsToEdit, linkBuilderController.operations);
      linkBuilderController.studyId = getStudyId(linkBuilderController.entity);
      const entityType = EntityService.getRootType(linkBuilderController.entity);
      const rootType = EntityService.getRootType(entityType);

      LinkType.query().$promise.then((types) => {
        linkBuilderController.types = _.filter(types, (type) => !type.sourceType || type.sourceType === rootType);
        if (linkBuilderController.types.length > 0) {
          linkBuilderController.typeId = linkBuilderController.types[0].id;
        }

        loadData().then(() => {
          linkBuilderController.selected = getSelected(linkBuilderController.links);
        });
      });
    };

    function getStudyId(entity) {
      if (angular.isUndefined(entity)) {
        return;
      }

      let studyId;
      if (angular.isDefined(entity.studyId)) {
        studyId = entity.studyId;
      } else if (entity.owner && entity.owner.type === 'study') {
        studyId = entity.owner.id;
      }
      return studyId;
    }

    function loadData() {
      delete linkBuilderController.links;
      return Link.usage({
        categoryId: linkBuilderController.categoryId,
        entityType: EntityService.getType(linkBuilderController.entity),
        entityId: linkBuilderController.entity.id,
        language: Language.get()
      }).$promise.then((links) => {
        linkBuilderController.links = links;
      });
    }

    function getSelected(links) {
      return _(links)
        .filter((link) => link.direction === 'OUTWARD')
        .filter((link) => link.typeId === linkBuilderController.typeId)
        .map('target')
        .value();
    }

    linkBuilderController.onType = function () {
      loadData().then(() => {
        linkBuilderController.selected = getSelected(linkBuilderController.links);
      });
    };

    linkBuilderController.onToggle = function (entity, selected) {
      if (selected) {
        createLink(entity);
      } else {
        deleteLink(entity);
      }
    };

    function createLink(target) {
      if (angular.isUndefined(linkBuilderController.typeId)) {
        return $q.resolve();
      }

      const model = {
        entity: EntityService.toReference(linkBuilderController.entity),
        typeId: linkBuilderController.typeId,
        target
      };

      return Link.store({
        content: model,
        comment: { message: '' }
      }).$promise.then(() => {
        loadData();
      });
    }

    function deleteLink(target) {
      const found = _.find(linkBuilderController.links, (link) => link.target.id === target.id && link.target.type === target.type);
      if (!found) {
        return $q.resolve();
      }

      return Link.remove({
        id: found.id
      }).$promise.then(() => {
        loadData();
      });
    }
  }
});
