'use strict';

angular.module('uasApp').component('htmlText', {
  bindings: {
    inputId: '@?',
    language: '@',
    editor: '@?',
    notificationTags: '<?',
    maxCharacters: '<?',
    isRequired: '<?',
    isDisabled: '<?',
    isReadOnly: '<?',
    editorOptions: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/html.text.html',
  controllerAs: 'htmlTextController'
});
